<template>
  <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card class="rounded-0">
      <b-row>
        <b-col cols="21" md="7" lg="7" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar :src="avatar" :text="avatarText(firma.firma_adi)" :variant="`light-success`" size="120px" rounded />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ firma.firma_adi }}
                </h4>
                <span class="card-text d-block pt-50">{{ firma.e_mail }}</span>
                <span class="card-text b-block pt-50">
                  <a :href="`http://${firma.domain}`" target="_blank">{{ firma.domain }}</a>
                </span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button squared @click="handlerUpdate(firma)" variant="primary"> Düzenle </b-button>
                <b-button squared variant="outline-danger" class="ml-1" @click="handlerRemove(firma.k_no)"> Sil </b-button>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-success" rounded> <feather-icon icon="CalendarIcon" /> </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ firma.lisans_sure | moment }}</h5>
                <small>Lisans Bitiş Tarihi</small>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="5" lg="5">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">Kayit No</span>
              </th>
              <td class="pb-50">
                {{ firma.k_no }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Kayit Tarihi</span>
              </th>
              <td class="pb-50">
                {{ firma.kayit_tarihi | moment }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Telefon</span>
              </th>
              <td class="pb-50">
                {{ firma.telefon }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="DollarSignIcon" class="mr-75" />
                <span class="font-weight-bold">Varsayilan Kur</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ firma.kur_turu }}
              </td>
            </tr>

            <tr>
              <th>
                <feather-icon icon="LockIcon" class="mr-75" />
                <span class="font-weight-bold">Statu</span>
              </th>
              <td>
                <b-badge :variant="`light-${statusColor(firma.statu)}`" class="p-1">
                  <feather-icon icon="UnlockIcon" class="mr-50" />
                  {{ statusText(firma.statu) }}
                </b-badge>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <!-- <firma-guncelle
      v-model="openModal"
      :openModal="openModal"
      :updateData="updateData"
      @closeModal="closeModal = $event"
      @firmaData="getfirmaData = $event"
    /> -->
    </b-card>
  </b-overlay>
</template>

<script>
import { toRefs, computed } from '@vue/composition-api';
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    firma: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { firma } = toRefs(props);

    expo.avatar = computed(() => {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
      if (Object.keys(firma.value).length) {
        if (firma.value.logo == 'default.png') {
          return '';
        } else {
          return `${base_url}uploads/logo/${firma.value.logo}`;
        }
      }
    });

    expo.avatarText = computed(() => {
      // if (!value) return '';
      // const name = value.slice(0, 1);
      // return name;
      return (value) => {
        if (!value) return '';
        const name = value.slice(0, 1);
        return name;
      };
    });

    expo.statusColor = computed(() => {
      const statusColor = {
        1: 'success',
        2: 'danger',
        3: 'info',
      };

      return (value) => statusColor[value];
    });

    expo.statusText = computed(() => {
      const statusText = {
        1: 'Aktif',
        2: 'Pasif',
        3: 'Bakım Modunda',
      };

      return (value) => statusText[value];
    });

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>

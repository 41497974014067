<template>
  <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card no-body class="border-primary rounded-0 p-1">
      <h3>Bakiye</h3>
      <hr />
      <div class="d-flex justify-content-between">
        <h6>Giriş Bakiye</h6>
        <h4 class="text-success">{{ balance.giris | cur }} {{ firma.kur_turu }}</h4>
      </div>
      <div class="d-flex justify-content-between">
        <h6>Çıkış Bakiye</h6>
        <h4 class="text-danger">{{ balance.cikis | cur }} {{ firma.kur_turu }}</h4>
      </div>
      <hr class="p-0" />
      <div class="d-flex justify-content-between">
        <h4>Toplam</h4>
        <h4 :class="balance.kalan < 0 ? 'text-danger' : 'text-success'">{{ balance.kalan | cur }} {{ firma.kur_turu }}</h4>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import store from '@/store';
import { computed, ref, toRefs } from '@vue/composition-api';
export default {
  props: {
    show: [Boolean],
    firma: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { firma } = toRefs(props);

    expo.balance = computed(() => {
      let balance = {
        giris: 0.0,
        cikis: 0.0,
        kalan: 0.0,
      };
      const hareketler = store.getters.getFirmaBalance;
      hareketler.map((value) => {
        if (value.gc_kodu == 'C') {
          balance.cikis += value.tutar;
        } else {
          balance.giris += value.tutar;
        }
        balance.kalan = balance.giris - balance.cikis;
      });
      return balance;
    });
    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <b-card no-body class="rounded-0">
    <b-overlay variant="white" :showLoading="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
              <b-form-input v-model="search" class="rounded-0 mr-1" placeholder="Arama..." />
              <!-- <v-select
                v-model="sube"
                :options="subeler"
                :reduce="(subeler) => subeler.k_no"
                label="sube_adi"
                :clearable="true"
                class="invoice-filter-select d-block"
                v-on:input="handlerSelectSube($event)"
              /> -->
            </div>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" squared @click="openModal = true"> Açılış Kartı Ekle </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col cols="12">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: search,
              }"
              :pagination-options="{
                enabled: true,
                perPage: 100,
                mode: 'pages',
              }"
              styleClass="vgt-table striped bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'islem_tipi'">
                  {{ IslemTipi(props.row.islem_tipi) }}
                </span>
                <span v-else-if="props.column.field === 'lisans_sure'">
                  <b-badge variant="primary" v-if="props.row.lisans_sure"> {{ props.row.lisans_sure }} Yıllık </b-badge>
                </span>
                <span v-else-if="props.column.field === 'tarih'">
                  {{ props.row.tarih | momentFull }}
                </span>
                <div v-else-if="props.column.field === 'tutar'">
                  <span>
                    {{ props.row.tutar | cur }} {{ props.row.kur_turu }}
                    <b-badge :variant="props.row.gc_kodu == 'C' ? 'light-danger' : 'light-success'">
                      {{ props.row.gc_kodu == 'C' ? 'C' : 'G' }}
                    </b-badge>
                  </span>
                </div>
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item @click="handlerUpdate(props.row)" v-show="!props.row.paket_k_no">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Düzenle</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handlerRemove(props.row.k_no)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Sil</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template slot="pagination-bottom">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <v-select
                      v-model="pageSize"
                      :options="perPageOptions"
                      :clearable="false"
                      class="invoice-filter-select"
                      @input="handlerPageSizeChange"
                    />
                  </div>
                  <div>
                    <b-pagination
                      :total-rows="count"
                      :per-page="pageSize"
                      v-model="page"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @change="handlerPageChange"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <firma-acilis-karti
      :openModal="openModal"
      :firma="firma"
      :updateData="updateData"
      :kurlar="kurlar"
      @closeModal="closeModal = $event"
    />
    <firma-sure-uzat
      :openModal="openSureModal"
      :firma="firma"
      :updateData="updateData"
      :kurlar="kurlar"
      @closeModal="closeModal = $event"
    />
  </b-card>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { POSITION } from 'vue-toastification';
import FirmaSureUzat from './firmaSureUzat.vue';
import FirmaAcilisKarti from './firmaAcilisKarti.vue';
import { ref, toRefs, computed, watch, onMounted } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    vSelect,
    VueGoodTable,
    FirmaSureUzat,
    FirmaAcilisKarti,
  },
  props: {
    firma: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const toast = useToast();
    const { firma } = toRefs(props);
    expo.show = ref(false);
    expo.subeler = ref([]);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.openModal = ref(false);
    expo.openSureModal = ref(false);

    expo.kurlar = ref([]);

    expo.count = ref(0);
    expo.page = ref(1);
    expo.pageSize = ref(20);
    expo.perPageOptions = ref([10, 20, 50, 75, 100]);

    expo.search = ref('');

    expo.columns = ref([
      {
        label: 'İşlem Tipi',
        field: 'islem_tipi',
        width: '10%',
      },
      {
        label: 'Lisans Süre',
        field: 'lisans_sure',
        width: '10%',
      },
      {
        label: 'Tarih',
        field: 'tarih',
        width: '15%',
      },
      {
        label: 'Tutar',
        field: 'tutar',
        width: '15%',
        tdClass: 'text-right',
      },
      {
        label: 'Açıklama',
        field: 'aciklama',
      },
      {
        label: 'Action',
        field: 'action',
        width: '15%',
        tdClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);

    expo.IslemTipi = computed(() => {
      const tipler = {
        sure: 'Süre Uzakma',
        acilis_karti: 'Açılış Kartı',
      };
      return (value) => tipler[value];
    });

    const getRequestParams = (firma_k_no, title, page, pageSize) => {
      let params = {};
      if (firma_k_no) {
        params['firma_k_no'] = firma_k_no;
      }
      if (title) {
        params['title'] = search;
      }
      if (page) {
        params['page'] = page - 1;
      }
      if (pageSize) {
        params['pageSize'] = pageSize;
      }
      return params;
    };

    onMounted(async () => {
      expo.show.value = true;

      await store.dispatch('kurTurleriListele');
      expo.kurlar.value = await store.getters.getKurTurleri;

      expo.rows.value = [];
      const params = getRequestParams(firma.value.k_no, expo.search.value, expo.page.value, expo.pageSize.value);
      await store
        .dispatch('firmaHareketListele', params)
        .then(async (res) => {
          expo.count.value = res.count;
          expo.rows.value = await store.getters.getFirmaHareketler;
          expo.show.value = false;
        })
        .catch((err) => {
          expo.show.value = false;
        });
    });

    expo.handlerUpdate = (task) => {
      if (task) {
        expo.updateData.value = task;
        if (task.islem_tipi == 'sure') {
          expo.openSureModal.value = true;
        } else {
          expo.openModal.value = true;
        }
      }
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('firmaHareketSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'CheckSquareIcon',
                    variant: 'danger',
                    text: 'Kayıt Silme Başarılı.',
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
            }
          });
        }
      });
    };
    expo.handlerPageChange = (event) => {
      expo.page.value = event;
      this.handlerGetData();
    };
    expo.handlerPageSizeChange = (value) => {
      expo.pageSize.value = value;
      this.handlerGetData();
    };

    watch(
      expo.closeModal,
      async (val) => {
        if (val == true) {
          expo.openModal.value = false;
          expo.openSureModal.value = false;
          expo.closeModal.value = false;
          expo.updateData.value = {};
          expo.rows.value = [];
          expo.rows.value = await store.getters.getFirmaHareketler;
        }
      },
      { deep: true }
    );
    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>

<template>
  <b-row>
    <b-col cols="12" md="8">
      <firma-info-kart :show="show" :firma="form" />
    </b-col>
    <b-col cols="12" md="4">
      <firma-bakiye-bilgi :show="show" :firma="form" />
    </b-col>

    <b-col cols="12">
      <b-tabs align="left">
        <b-tab>
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>Hareketler</span>
          </template>
          <b-card-text>
            <firma-hareketler :firma="form" />
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>Kullanıcılar</span>
          </template>
          <b-card-text> <firma-kullanicilar :firma="form" /> </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="AlignCenterIcon" />
            <span>Firma Logları</span>
          </template>
          <b-card-text> <firma-loglari /></b-card-text>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import store from '@/store';
import { useRouter } from '@core/utils/utils';
import { ref, onMounted } from '@vue/composition-api';
import FirmaLoglari from './component/firmaLoglari.vue';
import FirmaInfoKart from './component/firmaInfoKart.vue';
import FirmaHareketler from './component/firmaHareketler.vue';
import FirmaBakiyeBilgi from './component/firmaBakiyeBilgi.vue';
import FirmaKullanicilar from './component/firmaKullanicilar.vue';
export default {
  components: {
    FirmaLoglari,
    FirmaInfoKart,
    FirmaHareketler,
    FirmaBakiyeBilgi,
    FirmaKullanicilar,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();
    expo.show = ref(false);
    expo.form = ref({});

    onMounted(async () => {
      expo.show.value = true;
      const { route } = useRouter();
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        const firmalar = store.getters.getFirmalar;
        let sayfa = {};
        if (firmalar.length > 0) {
          sayfa = firmalar.find((x) => x.k_no === k_no);
          expo.form.value = { ...sayfa };
          expo.show.value = false;
        } else {
          await store.dispatch('firmafindOne', k_no).then((res) => {
            expo.form.value = res;
            expo.show.value = false;
          });
        }
      }

      console.log(expo.form.value);
    });

    // const handlerGetAllData = async () => {
    //   expo.show.value = true;
    //   const k_no = route.value.params.k_no;
    //   if (k_no != null) {
    //     await store.dispatch('firmafindOne', k_no).then((res) => {
    //       expo.form.value = res;
    //       expo.show.value = false;
    //     });
    //   } else {
    //     expo.show.value = false;
    //   }
    // };

    // handlerGetAllData();
    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>

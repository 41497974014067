<template>
  <b-modal
    @show="showModal"
    @hidden="hideModal"
    id="my-modal"
    ref="my-modal"
    size="md"
    class="rounded-0"
    centered
    no-close-on-backdrop
    hide-footer
    :title="`Firma Açılış Kartı ( ` + form.firma_adi + ` )`"
  >
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-text>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Fiş Tarihi">
                  <flat-pickr
                    v-model="date"
                    class="form-control rounded-0"
                    :config="config"
                    :events="config.events"
                    @on-change="handlerOnChangeDate"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Giriş | Çıkış">
                  <v-select
                    v-model="form.gc_kodu"
                    :options="DekontTipi"
                    :reduce="(statu) => statu.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Fiyat" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Fiyat">
                    <b-input-group :append="form.kur_turu" class="input-group-merge">
                      <b-form-input
                        placeholder="Tutar"
                        class="rounded-0"
                        v-model="form.tutar"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Açıklama" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Açıklama">
                    <b-form-textarea
                      id="aciklama"
                      class="rounded-0"
                      v-model="form.aciklama"
                      placeholder="Açıklama"
                      rows="2"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-button squared block class="mr-1" @click="hideModal">
                  <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" />
                  İptal
                </b-button>
              </b-col>
              <b-col cols="12" md="6">
                <b-button squared block variant="primary" type="submit" :disabled="show">
                  <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
                  <b-spinner class="mr-25" small variant="light" v-else />
                  Kaydet
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-overlay>
  </b-modal>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import { POSITION } from 'vue-toastification';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { ref, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  components: {
    vSelect,
    Cleave,
    flatPickr,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    kurlar: {
      type: Array,
      required: true,
    },
    firma: {
      type: Object,
      required: false,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { openModal, firma, updateData } = toRefs(props);
    expo.show = ref(false);
    expo.dekont = ref('G');
    expo.date = ref(moment().format('DD.MM.YYYY'));
    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    });

    expo.DekontTipi = ref([
      {
        id: 'G',
        title: 'Giriş Dekontu',
      },
      {
        id: 'C',
        title: 'Çıkış Dekontu',
      },
    ]);
    expo.form = ref({
      k_no: null,
      firma_k_no: null,
      firma_adi: null,
      islem_tipi: 'acilis_karti',
      tarih: moment().format('YYYY-MM-DD'),
      lisans_sure: 0,
      gc_kodu: 'G',
      tutar: 0,
      kur_turu: null,
      aciklama: null,
    });

    expo.handlerDekont = (event) => {
      event == true ? (expo.form.value.gc_kodu = 'G') : (expo.form.value.gc_kodu = 'C');
    };

    expo.handlerOnChangeDate = (event) => {
      if (event) expo.form.value.tarih = moment(String(event)).format('YYYY-MM-DD');
    };
    expo.showModal = () => {
      context.refs['my-modal'].show();
    };
    expo.hideModal = () => {
      context.refs['my-modal'].hide();
      context.emit('closeModal', true);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.onSubmit = async () => {
      expo.show.value = true;
      const baseUrl = expo.form.value.k_no == null ? 'firmaHareketEkle' : 'firmaHareketGuncelle';
      await store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            console.log(res.data.data);
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            if (expo.form.value.k_no != null) {
              expo.hideModal();
            }
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    watch(
      openModal,
      (val) => {
        if (val == true) {
          if (Object.keys(updateData.value).length) {
            expo.form.value = { ...updateData.value };
            expo.form.value.firma_adi = firma.value.firma_adi;
          } else {
            expo.form.value.firma_k_no = firma.value.k_no;
            expo.form.value.firma_adi = firma.value.firma_adi;
            expo.form.value.kur_turu = firma.value.kur_turu;
          }
          expo.showModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

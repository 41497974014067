var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-actions',{ref:"cardAction",attrs:{"title":"Firma Logları","icon":"AlignCenterIcon","actionCollapse":true,"actionRefresh":true,"actionNewAdd":false,"collapsed":true,"showLoading":_vm.show},on:{"refresh":function($event){return _vm.refreshStop($event)}},scopedSlots:_vm._u([{key:"filtrele",fn:function(){return [_c('b-row',{staticClass:"my-1 mx-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-input',{staticClass:"mr-1 rounded-0",attrs:{"placeholder":_vm.$t('global.arama')},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm,
        },"sort-options":{
          enabled: true,
          initialSortBy: { field: 'tarih' },
        },"pagination-options":{
          enabled: true,
          perPage: _vm.pageLength,
        },"styleClass":"vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'level')?_c('span',[_c('b-badge',{staticClass:"d-block m-1 rounded-0",attrs:{"variant":_vm.statusVariant(props.row.level)}},[_vm._v(" "+_vm._s(props.row.level)+" ")])],1):(props.column.field === 'tarih')?_c('span',{staticClass:"float-left"},[_vm._v(" "+_vm._s(_vm._f("momentFull")(props.row.timestamp))+" ")]):(props.column.field === 'message')?_c('span',[_vm._v(" "+_vm._s(props.row.message)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('v-select',{staticClass:"invoice-filter-select",attrs:{"options":_vm.perPageOptions,"clearable":false},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
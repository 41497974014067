<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Firma Logları"
      icon="AlignCenterIcon"
      :actionCollapse="true"
      :actionRefresh="true"
      :actionNewAdd="false"
      :collapsed="true"
      :showLoading="show"
      @refresh="refreshStop($event)"
    >
      <!-- actionCollapse true ise filtre kısmı calısırız -->
      <template v-slot:filtrele>
        <b-row class="my-1 mx-1">
          <b-col cols="12">
            <b-form-input v-model="searchTerm" class="mr-1 rounded-0" :placeholder="$t('global.arama')" />
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'tarih' },
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'level'">
              <b-badge :variant="statusVariant(props.row.level)" class="d-block m-1 rounded-0">
                {{ props.row.level }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'tarih'" class="float-left">
              {{ props.row.timestamp | momentFull }}
            </span>
            <span v-else-if="props.column.field === 'message'">
              {{ props.row.message }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <v-select
                  v-model="pageLength"
                  :options="perPageOptions"
                  :clearable="false"
                  class="invoice-filter-select"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </template>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import axiosIns from '@/libs/axios';
export default {
  components: {
    BCardActions,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      show: false,
      pageLength: 10,
      perPageOptions: [10, 25, 50, 100],
      columns: [
        {
          label: 'Statu',
          field: 'level',
        },
        {
          label: 'Tarih',
          field: 'tarih',
          type: 'date',
        },
        {
          label: 'Mesaj',
          field: 'message',
        },
        {
          label: 'Kullanıcı',
          field: 'user',
        },
      ],
      rows: [],
      searchTerm: '',
      statusFilter: 'Hepsini Göster',
    };
  },
  created() {
    this.handlerGetData();
  },
  computed: {
    statusVariant() {
      const statusColor = {
        error: 'light-danger',
        warn: 'light-warning',
        info: 'light-info',
      };

      return (status) => statusColor[status];
    },
  },
  methods: {
    async handlerGetData() {
      this.showLoading = true;
      await axiosIns.get('/logger/loglari-getir').then((res) => {
        if (res.data.success == true) {
          this.rows = res.data.data;
          this.showLoading = false;
        }
      });
    },
    refreshStop(event) {
      this.handlerGetData();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
